<template>
  <Dialog :commonDialog="dialog">
    <template v-slot:title>
      <v-layout>
        <v-flex>Visit Listing On Same Postal Code </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <draggable
          tag="ul"
          v-model="defaultColDefs"
          class="draggable-group"
          handle=".draggable-drag-icon"
        >
          <v-container class="py-0">
            <v-row class="py-0">
              <v-col md="12" class="py-0">
                <template v-for="(data, index) in visit_postal">
                  <h1 :key="index">
                    {{ add_person(data.available_visits) }}
                  </h1>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </draggable>
      </div>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="addVisit(visit_postal)"
      >
        Include into Remark
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import draggable from "vuedraggable";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { WeekEventBus } from "@/core/lib/message.lib";
import { map } from "lodash";
export default {
  name: "All-visit",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_designation_new: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postalcode: null,
      visit_postal: {},
      checkbox: false,
      defaultColShow: [],
      defaultColDefs: [],
      // indeterminate: false,
    };
  },
  methods: {
    add_person(data) {
      let barcodeData = map(data, (row) => row.barcode);
      if (barcodeData && barcodeData.length > 0) {
        return barcodeData.join(", ");
      }
      return "";
    },

    addVisit(data) {
      const remarkData = map(data, (row) => row.available_visits);

      const visitIds = remarkData[0].map((row) => row.barcode);

      WeekEventBus.$emit("get:get-visit", visitIds.toString());

      if (remarkData && remarkData.barcode && remarkData.barcode.length > 0) {
        return remarkData.join(", ");
      }
      this.$emit("close-dialog", true);
      return "";
    },
  },
  components: {
    draggable,
    Dialog,
    // currentUser,
  },
  mounted() {
    WeekEventBus.$on("visit-Postal-code", (param) => {
      this.visit_postal = param;

      // console.log(this.visit_postal, "test");
    });
  },
};
</script>
