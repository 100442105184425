import CommonMixin from "@/core/plugins/common-mixin";
import { POST, GET } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import SignaturePad from "signature_pad";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  props: {
    closerDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    ticketId: {
      type: Number,
      default: 0,
    },
    visitId: {
      type: Number,
      default: 0,
    },
    timesheetId: {
      type: Number,
      default: 0,
    },
    visit: {
      type: Number,
      default: 0,
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      engineerSignature: null,
      customerSignature: null,
      audioDataURL: null,
      disableVisit: false,
      timeTrackings: [],
      visitList: [],
      payment_mode_list: [],
      data: {
        timesheet: null,
        attachments: [],
        visit: 0,
        details: null,
        service_actiontaken: null,
      },
      has_payment: false,
      payment_mode: 1,
      amount_collected: null,
      collected_amount: null,
      collected_status: 1,
      status_list: [
        {
          text: "Paid",
          value: 1,
        },
        {
          text: "Partially Paid",
          value: 3,
        },
      ],
    };
  },
  watch: {
    closerDialog(param) {
      if (param) {
        this.init();
      }
    },
  },
  methods: {
    updateFiles(param) {
      this.data.attachments = param;
    },
    recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    updateOrCreate(action) {
      const _this = this;

      if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      }

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      signatures.push({ engineer: this.engineerSignature.toDataURL() });
      signatures.push({ customer: this.customerSignature.toDataURL() });

      const formData = {
        action,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.timesheetId,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        service_actiontaken: _this.data.service_actiontaken,
      };
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.ticketId + "/visit/" + _this.visitId + "/closer",
          data: formData,
        })
        .then(() => {
          _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    get_payment_mode() {
      this.$store
        .dispatch(GET, { url: "setting/payment-mode" })
        .then((response) => {
          this.payment_mode_list = ObjectPath.get(
            response,
            "data.payment_mode",
            []
          );
          this.payment_mode = ObjectPath.get(this.detail, "payment_mode.id", 1);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    init() {
      const _this = this;
      setTimeout(function () {
        _this.$nextTick(() => {
          let ecanvas = _this.$refs["engineersignature"];
          let eparentDiv = document.getElementById("engineer-signature");
          if (eparentDiv) {
            let eparentWidth = eparentDiv.offsetWidth;
            ecanvas.setAttribute("width", eparentWidth);
            _this.engineerSignature = new SignaturePad(ecanvas);
          }

          let ccanvas = _this.$refs["customersignature"];
          let cparentDiv = document.getElementById("customer-signature");
          if (cparentDiv) {
            let cparentWidth = cparentDiv.offsetWidth;
            ccanvas.setAttribute("width", cparentWidth);
            _this.customerSignature = new SignaturePad(ccanvas);
          }
        });
      }, 500);
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    this.init();
    this.get_payment_mode();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
